import React, {useState, useEffect} from 'react'
import '../css/Row.css'
import {useDataLayerValue} from '../DataLayer'

function Row() {

    const [{ demo }, dispatch] = useDataLayerValue();
    let prueba =4


    const setDemo = (num) => {
        dispatch({
            type: "SET_DEMO",
            demo: num
        })

        console.log(demo)
    }

    
    //const [demoValue, setDemoValue] = useState(0);

    //console.log(demo)

    
    const proj1 = () =>{
        setDemo(1)
    }
    const proj2 = () =>{
        setDemo(2)
    }
    const proj3 = () =>{
        setDemo(3)
    }
    const proj4 = () =>{
        setDemo(4)
    }
    const proj5 = () =>{
        setDemo(5)
    }

    return (
        <div className="row">
            <div className="row__posters">

                <div className="square" onClick={proj1} ><img ></img> <h1> TIM - Inteligencia Artificial y RH   </h1></div>
                <div className="square casitas" onClick={proj2} ><h1 >Sistema Recomendación inmobiliarias CDMX</h1></div>
                
                <div className="square tracker"onClick={proj3} ><img></img><h1>Tracker Covid-19</h1></div>
                <div className="square" onClick={proj4} ><img></img><h1>Bancos y las Redes Neuronales</h1></div>
                <div className="square" onClick={proj5} ><img></img><h1>Kate, percepción de Marca en Redes Sociales</h1></div>
                
            </div>
        </div>
    )
}

export default Row
