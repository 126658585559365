import React from 'react'
import '../css/Home.css'

function Home() {
    return (
        <div className="home">
            <div className="home__banner">

                <span><h1>CRECE TU EMPRESA</h1> <br></br><h1>CON AYUDA DE <span className="datos">DATOS </span></h1> </span>
                <br/>
                <a href='' className="home__bannerButton">Nuestros Servicios </a>
            </div>

            

            <div className="home__valueProposition">
                <p>Hola</p>
            </div>
            
            
            

        </div>
    )
}

export default Home
