import React, {useEffect, useState} from 'react'
import '../css/Header.css' 
import SearchIcon from '@material-ui/icons/Search';
import { Link } from "react-router-dom";

import MenuIcon from '@material-ui/icons/Menu'; 
function Header() {
    const [show, handleShow] = useState(false);
    useEffect( ()=>{
        window.addEventListener('scroll', ()=>{
            if(window.scrollY > 50){
                handleShow(true);
            }else{
                handleShow(false);
            }
        })
    },)

    const hide=()=>{
        let element = document.getElementById("navigation-dropdown");
        element.classList.toggle("hide");
        element.classList.toggle("show");
    }

    const  dropIt=()=> {
        let element = document.getElementById("navigation-dropdown");
        element.classList.toggle("hide");
        element.classList.toggle("show");
        document.getElementById("mobileUl").style.visibility = "hidden";
        element.addEventListener("transitionend", function(event) {
            document.getElementById("mobileUl").style.visibility = "visible"; 
          }, false);
        
    }

    return (
        <div className={`header ${show && "header_scrolled"}` }>
            
            <div className="header__web">
                <div className="header__left">
                    <Link className="link" to ="/">
                    <img className= "header__logo" src={process.env.PUBLIC_URL +"./images/vaetro_logo_hor.png"} alt="Vaetro"></img>
                    </Link>
                </div>
                <div className ="header__center">
                    <ul className="header__ul "  >
                        <Link className="link" to="/servicios"    style={{ textDecoration: 'none' }}>
                            <li className="header__ul__li"  >SERVICIOS</li>
                        </Link>
                        <Link className="link" to="/portfolio" style={{ textDecoration: 'none' }}>
                            <li  className="header__ul__li"> PORTAFOLIO</li>
                        </Link>
                        
                        <Link className="link" to="/about"   style={{ textDecoration: 'none' }}>
                            <li className="header__ul__li">ACERCA DE</li>
                        </Link>
                        
                        <Link className="link" to="/contact"   >
                            <li className="header__ul__li">CONTACTO</li>
                        </Link>
                        
                    </ul>

                </div>

                <div className="header__right">
                    <SearchIcon />
                </div>

            </div>

            <div className="header__mobile">
                <div className="top">
                    <div className="header__left">
                        <Link className="link" to ="/">
                            <img className= "logo__mobile" src={process.env.PUBLIC_URL +"./images/vaetro_logo_hor.png"} alt="Vaetro"></img>
                        </Link>
                    </div>

                    <div className="mobilenav">
                        <MenuIcon className="mobilenav__icon" style={{fontSize: 30}}     
                            onClick={dropIt}
                        />
                    </div>
                    
                </div>
                

                <div id="navigation-dropdown" className="hide" onClick={hide} >
                <ul  className="mobileList " id="mobileUl">
                        <Link className="link" to="/servicios" style={{ textDecoration: 'none' }}>
                            <li  className="mobileList">SERVICIOS</li>
                        </Link>
                        <Link className="link" to="/portfolio" style={{ textDecoration: 'none' }}>
                            <li className="mobileList"> PORTAFOLIO</li>
                        </Link>
                        <Link className="link" to="/about"   style={{ textDecoration: 'none' }}>
                            <li className="mobileList">ACERCA DE</li>
                        </Link>
                        
                        <Link className="link" to="/contact"   >
                            <li className="mobileList">CONTACTO</li>
                        </Link>
                        
                    </ul>
                </div>
                

            </div>
        
        
        
        </div>
        
    )
}

export default Header
