import React, {useState, useEffect} from 'react';
import '../css/Portfolio.css'
import Row from './Row'
import Banner from './Banner'
import {useDataLayerValue} from '../DataLayer'

function Portfolio() {
    //const [{demo}, dispatch] = useDataLayerValue();
    //console.log(demo)
    let demo =1;
    return (
        <div className="Portfolio">

          <Banner ban='Conoce nuestros proyectos'/>
          <Row />
            <div className="demo__content" >
              <div className="description__left">
                {
                (demo == 1)? <h1>Descripción Tim</h1> : 
                (demo == 2)? <h1>Descripción Casitas</h1>  : 
                (demo == 3)? <h1>Descripción Tracker</h1> :
                (demo == 4)? <h1>Descripción Banco y NN</h1> : <h1>Descripción Kate</h1>
                }
          </div>
          <div className="demo__right">
            {
            (demo == 1)? <h1> Tim</h1> : 
            (demo == 2)? <h1>Casitas</h1>  : 
            (demo == 3)? <h1>Tracker</h1> :
            (demo == 4)? <h1>Banco y NN</h1> : 
            <h1>Kate</h1>
            }
          </div>
          </div>
        </div>

    )
}

export default Portfolio
