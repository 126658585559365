import React from 'react'
import '../css//Banner.css'
function Banner({ban ="Banner" }) {
    return (
        <div className="banner">
            <h1 className="banner_title">{ban}</h1>
            <div className="banner__line"></div>
        </div>
    )
}

export default Banner
