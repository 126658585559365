import React from 'react'
import '../css/Servicios.css'
import Banner from './Banner'
function Servicios() {
    return (
        <div className="servicios">
            
            <Banner ban='NUESTROS SERVICIOS'/>
            
        </div>
    )
}

export default Servicios
