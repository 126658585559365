import React, {useState} from 'react'
import '../css/Contact.css'
import Banner from './Banner'

function Contacto() {
    const [name, setName] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [nosotros, setNosotros] = useState('');

   const sendMail= (e) => {
    e.preventDefault();
    window.Email.send({
       Host: "smtp.gmail.com",
       Username : 'tim.contacto.vaetro@gmail.com',
       Password : "123Maquinitas",
       To : 'alecbarba@gmail.com',
       From : 'tim.contacto.vaetro@gmail.com',
       Subject : subject,
       Body : "Sent from: "+name+" with the email: "+email+"\n, The message is:  "+message+ "\n De la empresa: " + empresa
       +"\n Se enteró de nosotros por: " + nosotros , 
       }).then(
          message => alert("Se ha enviado tu correo. ¡En Breve nos contactaremos contigo!")
       );
    }
    return (
        <div className="contact">
            <Banner ban="contacto"/>

            <div className="contact__content">
                <div className="contact__contentLeft">
                    <h3>Dirección y datos Contacto</h3>
                    <br />
                    <h5>Avenida de los cacahuates, 123. Col. Walt Disney</h5> 
                    <br />
                    <h5>tel. 5534 8292 41</h5> 
                    <br />
                    <h5>contacto@vaetro.com</h5> 



                </div>
                <div className="contact__contentRight">
                    <form id="contactForm" >
                            
                        <div>
                                <label htmlFor="contactName"> <span className="required"></span></label>
                                <input value={name} placeholder="Nombre" type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={e => setName(e.target.value)}/>
                        </div>

                        <div>
                                <label htmlFor="Empresa"></label>
                                <input value={email} placeholder="Empresa (Opcional)" type="text" defaultValue="" size="35" id="Empresa" name="Empresa" onChange={e=> setEmpresa(e.target.value)}/>
                        </div>


                        <div>
                                <label htmlFor="contactEmail"><span className="required"></span></label>
                                <input value={email} placeholder="Correo electrónico" type="text" defaultValue="" size="70" id="contactEmail" name="contactEmail" onChange={e=> setEmail(e.target.value)}/>
                        </div>

                        <div>
                                <label htmlFor="contactAbout"></label>
                                <input value={subject} placeholder="¿Cómo te enteraste de nosotros?" type="text" defaultValue="" size="35" id="contactAbout" name="contactAbout" onChange={e => setNosotros(e.target.value)}/>
                        </div>
                        
                        <div>
                                <label htmlFor="contactSubject"></label>
                                <input value={subject} type="text" placeholder="Asunto (Opcional)" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={e => setSubject(e.target.value)}/>
                        </div>

                        <div>
                            <label htmlFor="contactMessage"><span className="required"></span></label>
                            <textarea placeholder="Cuéntanos tu proyecto" value={message} onChange={e => setMessage(e.target.value)} cols="50" rows="10" id="contactMessage" name="contactMessage"></textarea>
                        </div>

                        <div className="buttonArea">
                            <button type='submit' onClick={sendMail} className="submit">Enviar</button>
                        </div>
                            
                        </form>
                    </div>
            </div>
        </div>
    )
}

export default Contacto
