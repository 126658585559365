import React from 'react'
import '../css//Blog.css'
import Banner from './Banner'
function Blog() {
    return (
        <div className="blog">
            <Banner ban="NUESTRO BLOG"/>
        </div>
    )
}

export default Blog
