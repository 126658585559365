import React from 'react'
import '../css/Footer.css'

import { Link } from "react-router-dom";

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
function Footer() {
    return (
        <div className="footer">
            {/**Logo blanco y negro con socials */}
            <div className="footer__icons">
                <Link to="/" className="footer__Vaetro">  
                    <img src={process.env.PUBLIC_URL +"./images/vaetro_logo_hor_w.png"} alt="Vaetro" className="footer__img"></img>
                </Link> 
                
                <div className="footer__icons__social">
                    <a href="https://www.facebook.com/alec.barba1/" target="_blank" rel="noopener"> <FacebookIcon /> </a>
                    <a href="https://twitter.com/vaetro" target="_blank" rel="noopener"><TwitterIcon  /></a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener"><LinkedInIcon /></a>
                    
                    
                </div>
            </div>
            {/**Menú desktop only :) */}
            <div className="footer__right">
                <div className="footer__menu">
                    <ul className="footer__ul "  >
                        <Link className="link" to="/servicios"    style={{ textDecoration: 'none' }}>
                            <li className="footer__li"  >SERVICIOS</li>
                        </Link>
                        <Link className="link" to="/portfolio" style={{ textDecoration: 'none' }}>
                            <li  className="footer__li"> PORTAFOLIO</li>
                        </Link>
                        
                        <Link className="link" to="/about"   style={{ textDecoration: 'none' }}>
                            <li className="footer__li">ACERCA DE</li>
                        </Link>
                        
                        <Link className="link" to="/contact"    style={{ textDecoration: 'none' }}>
                            <li className="footer__li">CONTACTO</li>
                        </Link>
                    </ul> 
                </div>

                <div className="footer__contactInfo">
                    <p>Avenida de los cacahuates, 123. +52 55 1234 5678</p>
                    <p>contacto@vaetro.com</p>
                </div>

            </div>
            
        </div>
    )
}

export default Footer
