
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import Header from './components/Header';
import Home from './components/Home'
import Portfolio from './components/Portfolio'
import Servicios from './components/Servicios'
import Footer from './components/Footer'
import Contacto from './components/Contacto'
import Blog from './components/Blog'
import About from './components/About'

import ScrollToTop from './components/ScrollToTop'

import {useDataLayerValue} from './DataLayer'

function App() {

  return (
    <Router >
    <ScrollToTop/>
      <div className="App">
      <Header />
      <div className="header__spacing"></div>
        <Switch>
          <Route path="/portfolio">
                <Portfolio />
                
          </Route>
          <Route path="/servicios">
            <Servicios />
          </Route>
          <Route path="/contact">
            <Contacto />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>

          <Route path="/about">
            <About />
          </Route>

          <Route path="/">
              <Home />
          </Route>

        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
