import React from 'react'
import '../css/About.css'
import Banner from './Banner'
function About() {
    return (
        <div className='about'>
            <Banner ban="Acerca De"/>
        </div>
    )
}

export default About
