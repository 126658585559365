export const initialState = {
    demo: 1,
}

const reducer = (state, action) =>{
console.log(action);

//Action has 2 things: -> type, [payload]

    switch(action.type){

        case 'SET_DEMO': 
            return {
                ...state, //Keep what was in current state
                demo: action.demo
            }

        
        default:
            return state
    }
}


export default reducer;